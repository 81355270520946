'use client'

import React, {useEffect, useState} from 'react';
import {useBreakpoint} from "@/hooks/breakpoint";
import {PimcoreImage as PimcoreImageType, PimcoreVideoThumbnail} from '@/types/pimcore';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';

interface HeroHeaderVideoProps {
    videoThumbnail: PimcoreVideoThumbnail | null,
    videoThumbnailMobile: PimcoreVideoThumbnail | null,
    fallbackImage: PimcoreImageType | null
}

export default function HeroHeaderVideo({videoThumbnail, videoThumbnailMobile, fallbackImage}: HeroHeaderVideoProps) {


    const isMd = useBreakpoint('md');
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        setInitialized(true);

        return () => {
            setInitialized(false);
        };
    });

    return initialized && (
        <div className="relative h-full w-full transition duration-300">
            {(fallbackImage) && (
                <PimcoreImage className="h-full absolute w-full object-cover"
                              image={fallbackImage}/>
            )}
            {isMd && videoThumbnail?.status === 'finished' && videoThumbnail?.formats?.mp4 ? (
                <video
                    className={`bg-transparent h-full absolute w-full object-cover ${videoThumbnailMobile?.status === 'finished' ? 'hidden md:block' : ''}`}
                    muted
                    autoPlay
                    playsInline
                    loop
                    src={videoThumbnail.formats.mp4} />
            ) : <></>}
            {!isMd && videoThumbnailMobile?.status === 'finished' && videoThumbnailMobile?.formats?.mp4 ? (
                <video
                    className={`bg-transparent h-full absolute w-full object-cover ${videoThumbnail?.status === 'finished' ? 'block md:hidden' : ''}`}
                    muted
                    autoPlay
                    playsInline
                    loop
                    src={videoThumbnailMobile.formats.mp4} />
            ) : <></>}
        </div>
    );
}
